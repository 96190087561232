import { atom, selector } from 'recoil';
import type { ConvertedFile, OriginalFile, STSSoundSettings, TTSSoundSettings } from '@modules/types/studio';
import type { VoiceModel } from '@modules/types/voice';

export const RType = atom<null | 'file' | 'recording' | 'typing'>({
    key: 'type',
    default: null,
});

export const RSelectedVoice = atom<VoiceModel | null>({
    key: 'selectedVoice',
    default: null,
});

export const ROriginalFile = atom<OriginalFile | null>({
    key: 'originalFile',
    default: null,
});

export const RScript = atom<string>({
    key: 'script',
    default: '',
});

export const RScriptList = atom<{ text: string; intonation: number }[]>({
    key: 'scriptList',
    default: [{ text: '', intonation: 0 }],
});

export const RCharacterCount = selector({
    key: 'characterCount',
    get: ({ get }) => {
        const script = get(RScript);

        return script.length;
    },
});

export const RConvertedFile = atom<ConvertedFile | null>({
    key: 'convertedFile',
    default: null,
});

export const RSTSSettings = atom<STSSoundSettings>({
    key: 'stsSoundSettings',
    default: {
        type: 'STS',
        pitch: 0,
        tempo: 100,
        volume: 1,
        pitchSettingMode: 'auto',
    },
});

export const RTTSSettings = atom<TTSSoundSettings>({
    key: 'ttsSoundSettings',
    default: {
        type: 'TTS',
        version: 2,
        emotion: 'neutral',
        pause: 0.625,
        pitch: 0,
        tempo: 100,
        volume: 1,
        language: 'ko',
    },
});

// export const RSoundSettings = atom<STSSoundSettings | TTSSoundSettings>({
//     key: 'soundSettings',
//     default: {
//         type: 'STS',
//         pitch: 0,
//         tempo: 100,
//         volume: 1,
//         pitchSettingMode: 'auto',
//     },
// });
